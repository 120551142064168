import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Container, Layout } from 'layout';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import Typography from 'common/Typography';

import './LdsPage.scss';

const LdsPage: FC<LdsPageTypes.LdsProps> = ({
  data: {
    lds: { urls, seo, body },
    fontsToPreload,
  },
}) => {
  const { title, keywords, description } = seo;

  return (
    <Layout>
      <Seo {...{ urls, seo }} fontsToPreload={fontsToPreload?.nodes} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      <Container className="lds-page__container">
        <Typography dangerouslySetInnerHTML={body} />
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String, $path: [String], $fonts: [String] = []) {
    lds(lang: { eq: $lang }, url: { in: $path }) {
      seo {
        ...SEOStructureFragment
      }
      urls {
        ...UrlsFragment
      }
      body
    }
    fontsToPreload: allFile(filter: { relativePath: { in: $fonts } }) {
      nodes {
        publicURL
      }
    }
  }
`;

export default LdsPage;
